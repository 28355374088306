<template>
  <UiSidePanelForm
    :model-value="modelValue"
    :title="teamToEdit ? 'Edit team' : 'Create new team'"
    description="Team can include one Sales manager and unlimited number of agents. Agent can stay only in one team at once, so if you don't see him in the list - please change his team later."
    :primary-button-text="teamToEdit ? 'Edit team' : 'Create team'"
    secondary-button-text="Cancel"
    :disabled="loading"
    @update:model-value="cancel"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputTextField
        v-model="team.name"
        label="Team name"
        name="Team Name"
        placeholder="Team name"
        class="mb-4"
        :error="v$.name.$errors[0] ? v$.name.$errors[0].$message.toString() : ''"
      />
      <UiInputSelect
        v-model="team.manager_id"
        :items="managersItems"
        name="Manager"
        label="Sales manager"
        avatar
        class="mb-4"
        :disabled="teamToEdit && !useHasPermissions([PERMISSIONS.EDIT_TEAM_MANAGER])"
        placeholder="Select sales manager for this team"
        :error="v$.manager_id.$errors[0] ? v$.manager_id.$errors[0].$message.toString() : ''"
      />
      <div class="mb-9 flex flex-row items-end gap-4">
        <UiInputSelect
          v-model="team.temporaryAgentsIds"
          :items="agentsItems"
          multiple
          name="Agents"
          avatar
          label="Users"
          :disabled="teamToEdit && !useHasPermissions([PERMISSIONS.EDIT_TEAM_AGENTS])"
          placeholder="Search or add from the list"
          :error="v$.temporaryAgentsIds.$errors[0] ? v$.temporaryAgentsIds.$errors[0].$message.toString() : ''"
        />
        <UiButtonBase id="add_agents" :disabled="!team.temporaryAgentsIds.length" @click="addAgents">Add</UiButtonBase>
      </div>
      <UiStickyColumnsTable
        v-if="team.agent_ids.length"
        :items="agents"
        show-action
        :columns="[
          { text: 'User name', value: 'name' },
          { text: 'Role', value: 'role.name' },
        ]"
      >
        <template #action="{ item }">
          <transition name="fade" mode="out-in">
            <UiIcon
              v-if="!teamToEdit || (teamToEdit && useHasPermissions([PERMISSIONS.EDIT_TEAM_AGENTS]))"
              name="trash"
              @click="removeAgent(item)"
            /> </transition></template
      ></UiStickyColumnsTable>
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import flatMap from 'lodash/flatMap'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import { PERMISSIONS, ROLES } from '~/constants'

import type { InputItem, Team, User } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'edited', 'created'])

type Props = {
  modelValue: boolean
  teamToEdit?: Team
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  teamToEdit: undefined,
})

const loading = ref(false)
const usersItems = ref<InputItem[]>([])
const agentsItems = ref<InputItem[]>([])
const managersItems = ref<InputItem[]>([])

onNuxtReady(() => getUsers())

const getUsers = async () => {
  const users = await useGetUsers()
  usersItems.value = users.map((u: User) => ({ text: u.name, value: u.id, ...u }))
  managersItems.value = users
    .filter(
      (u: User) =>
        [ROLES.MANAGER, ROLES.ADMIN].includes(u.role?.code!) &&
        (!u.teams?.length || u.teams[0]?.id === props.teamToEdit?.id)
    )
    .map((u: User) => ({ ...u, text: u.name, value: u.id }))
  const items = users
    .filter((u: User) => (u.role?.code === ROLES.AGENT || u.role?.code === ROLES.SDR) && !u.teams?.length)
    .map((c: User) => ({ text: c.name, value: c.id, ...c }))
  const sortedData = sortBy(items, 'role.name')
  const groupedData = groupBy(sortedData, 'role.name')

  const transformedArray = flatMap(groupedData, (items, key) => {
    return [{ value: 'heading', text: key }, ...items]
  })

  agentsItems.value = transformedArray
}

const team = ref<Team>(
  props.teamToEdit
    ? {
        ...toRaw(props.teamToEdit),
        temporaryAgentsIds: [],
        agent_ids: props.teamToEdit.users
          .filter((u: User) => u.role?.code === ROLES.AGENT || u.role?.code === ROLES.SDR)
          .map((u: User) => u.id),
        manager_id: props.teamToEdit.users.find((u: User) => [ROLES.MANAGER, ROLES.ADMIN].includes(u.role?.code!))?.id,
      }
    : {
        name: '',
        manager_id: undefined,
        agent_ids: [],
        temporaryAgentsIds: [],
      }
)

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  manager_id: { required: helpers.withMessage('The manager is required', required) },
  agent_ids: { requiredIfValue: helpers.withMessage('At least one agent required', requiredIf(!props.teamToEdit)) },
  temporaryAgentsIds: {
    requiredIfValue: helpers.withMessage(
      'At least one agent required',
      requiredIf(!team.value.agent_ids?.length && !props.teamToEdit)
    ),
  },
}))

const v$ = useVuelidate(rules, team.value)

const addAgents = () => {
  team.value.agent_ids?.push(...team.value.temporaryAgentsIds)
  team.value.temporaryAgentsIds = []
}

const removeAgent = (agent: User) => {
  team.value.agent_ids = team.value.agent_ids.filter((id: number) => id !== agent.id)
}

const agents = computed(() => usersItems.value.filter((u: InputItem) => team.value.agent_ids?.includes(u.value)))

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    try {
      loading.value = true
      if (props.teamToEdit) {
        const teamEdited = await useUpdateTeam(team.value)
        emits('edited', teamEdited)
      } else {
        const teamCreated = await useCreateTeam(team.value)
        emits('created', teamCreated)
      }
      // Update users cache && this user
      await Promise.all([useGetUsers(true), useGetProfile()])
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}

const cancel = () => {
  team.value = {
    name: '',
    manager_id: undefined,
    agent_ids: [],
    temporaryAgentsIds: [],
  }
  v$.value.$reset()
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
